// import styled from "styled-components"
import SlideShow from "../components/SlideShow"

const Form = () => {
	return (
		<>
			<SlideShow />
		</>
	)
}

export default Form
